<template>
    <tr v-if="item.especialista || showAll">
        <td>
            <span class="blocked px-1">{{ item.class }}</span>
        </td>
        <td>{{ mode }}</td>
        <td>{{ configuracion }}</td>
        <td>{{ ts }}</td>
        <td>{{ te }}</td>
        <td class="text-center">
            <button class="btn btn-sm btn-primary px-1 py-0" @click="handleEdit">Editar</button>
            <button class="btn btn-sm btn-danger px-1 py-0 ms-1" @click="handleRemove">
                Borrar
            </button>
        </td>
    </tr>
</template>
<script>
import { computed } from 'vue';
import { DateTime } from 'luxon';

export default {
    name: 'IntervalDisabledTableRow',
    components: {},
    props: {
        item: { type: Object, required: true },
        showAll: { type: Boolean, default: false },
    },
    setup(props, { emit }) {
        const mode = computed(() => {
            if (props.item.timeStart) {
                return 'Fijo';
            }
            return 'Periodico';
        });

        const configuracion = computed(() => {
            if (props.item.timeStart) {
                const ts = DateTime.fromISO(props.item.timeStart);
                return `${ts.toFormat("EEEE d 'de' LLLL", { locale: 'co' })}`;
            }
            const date = DateTime.now().set({ weekday: props.item.weekDay });

            return `Todos los ${date.toFormat('EEEE', { locale: 'co' })}`;
        });

        const ts = computed(() => {
            let dts;
            if (props.item.timeStart) {
                dts = DateTime.fromISO(props.item.timeStart);
            } else {
                dts = DateTime.now().set({
                    hour: props.item.startHour,
                    minute: props.item.startMin,
                });
            }

            return dts.toFormat('hh:mm a', {
                locale: 'co',
            });
        });

        const te = computed(() => {
            let dte;
            if (props.item.timeEnd) {
                dte = DateTime.fromISO(props.item.timeEnd);
            } else {
                dte = DateTime.now().set({
                    hour: props.item.endHour,
                    minute: props.item.endMin,
                });
            }

            return dte.toFormat('hh:mm a', {
                locale: 'co',
            });
        });

        const handleEdit = () => {
            emit('onEdit', props.item);
        };

        const handleRemove = () => {
            emit('onRemove', props.item.id);
        };

        return {
            mode,
            configuracion,
            ts,
            te,
            handleEdit,
            handleRemove,
        };
    },
};
</script>
<style scoped>
.blocked {
    background-color: #d0d0d073;
    color: gray;
    padding-block: 1px;
}
</style>
