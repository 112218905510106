<template>
    <Datepicker
        :inputClassName="`form-control ${size}`"
        v-model="date"
        :time-picker="true"
        v-if="!onlyRead"
        format="HH:mm aa"
        mode-height="120"
        select-text="Confirmar"
        :is-24="false"
        minutes-grid-increment="5"
        ><template #dp-input="{ value }"
            ><input :class="`form-control ${size}`" type="text" :value="value" /></template
    ></Datepicker>
    <span class="d-flex align-items-center h-100 ps-1" v-else>{{ formatedDate }}</span>
</template>

<script>
// @update:modelValue="handleDate"
// eslint-disable-next-line object-curly-newline
import { computed } from 'vue';
import { DateTime } from 'luxon';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
    name: 'TimeInput',
    components: { Datepicker },
    props: {
        modelValue: { type: String },
        onlyRead: {
            type: Boolean,
            deafult: false,
        },
        size: {
            type: String,
            deafult: '',
        },
    },
    setup(props, { emit }) {
        const date = computed({
            get() {
                if (props.modelValue) {
                    const time = DateTime.fromISO(props.modelValue);
                    return { hours: time.hour, minutes: time.minute };
                }
                return '';
            },
            set(value) {
                const primer = DateTime.fromObject({ ...value }).toISO();
                emit('update:modelValue', primer);
                emit('onChange', primer);
            },
        });

        const formatedDate = computed(() => DateTime.fromISO(date.value).toFormat('HH:mm'));

        return {
            date,
            formatedDate,
        };
    },
};
</script>

<style scoped></style>
