<template>
    <div class="col">
        <div class="mb-3 row">
            <label class="col-sm-3 col-form-label">Tipo</label>
            <div class="col-sm-9">
                <select
                    class="form-select btn-square digits"
                    v-model="formType"
                    :disabled="changedDisabled"
                >
                    <option value="consulta">Consulta</option>
                    <!-- <option value="procedimientos">Procedimientos</option> -->
                    <option value="blocked" v-if="showBlocked">No disponible</option>
                </select>
            </div>
        </div>
        <div class="mb-3 row" v-if="formType === 'consulta'">
            <label class="col-sm-3 col-form-label">Dias</label>
            <div class="col-sm-9">
                <div class="m-checkbox-inline" v-if="formType !== 'blocked'">
                    <div class="form-check form-check-inline checkbox checkbox-dark mb-0">
                        <input
                            class="form-check-input"
                            :id="`${ckey}-inline-consulta`"
                            type="checkbox"
                            v-model="formTypes"
                            value="consulta"
                        />
                        <label class="form-check-label" :for="`${ckey}-inline-consulta`">Consultas</label>
                    </div>
                    <div class="form-check form-check-inline checkbox checkbox-dark mb-0">
                        <input
                            class="form-check-input"
                            :id="`${ckey}-inline-procedimientos`"
                            type="checkbox"
                            v-model="formTypes"
                            value="procedimiento"
                        />
                        <label class="form-check-label" :for="`${ckey}-inline-procedimientos`">Procedimientos</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-3 row">
            <label class="col-sm-3 col-form-label">Modo</label>
            <div class="col-sm-9">
                <select
                    class="form-select btn-square digits"
                    v-model="formMode"
                    :disabled="changedDisabled"
                >
                    <option v-for="(o, i) in formModeOptions" :key="i" :value="o.value">
                        {{ o.name }}
                    </option>
                </select>
            </div>
        </div>
        <div class="mb-3 row" v-if="showDate">
            <label class="col-sm-3 col-form-label">Fecha</label>
            <div class="col-sm-9">
                <DateInput v-model="formDate" />
            </div>
        </div>
        <div class="mb-3 row" v-if="showDays">
            <label class="col-sm-3 col-form-label">Dias</label>
            <div class="col-sm-9">
                <div class="m-checkbox-inline" v-if="formType !== 'blocked'">
                    <div class="form-check form-check-inline checkbox checkbox-dark mb-0">
                        <input
                            class="form-check-input"
                            :id="`${ckey}-inline-Lunes`"
                            type="checkbox"
                            v-model="formDays"
                            value="1"
                        />
                        <label class="form-check-label" :for="`${ckey}-inline-Lunes`">Lunes</label>
                    </div>
                    <div class="form-check form-check-inline checkbox checkbox-dark mb-0">
                        <input
                            class="form-check-input"
                            :id="`${ckey}-inline-Martes`"
                            type="checkbox"
                            v-model="formDays"
                            value="2"
                        />
                        <label class="form-check-label" :for="`${ckey}-inline-Martes`"
                            >Martes</label
                        >
                    </div>
                    <div class="form-check form-check-inline checkbox checkbox-dark mb-0">
                        <input
                            class="form-check-input"
                            :id="`${ckey}-inline-Miercoles`"
                            type="checkbox"
                            v-model="formDays"
                            value="3"
                        />
                        <label class="form-check-label" :for="`${ckey}-inline-Miercoles`"
                            >Miercoles</label
                        >
                    </div>
                    <div class="form-check form-check-inline checkbox checkbox-dark mb-0">
                        <input
                            class="form-check-input"
                            :id="`${ckey}-inline-Jueves`"
                            type="checkbox"
                            v-model="formDays"
                            value="4"
                        />
                        <label class="form-check-label" :for="`${ckey}-inline-Jueves`"
                            >Jueves</label
                        >
                    </div>
                    <div class="form-check form-check-inline checkbox checkbox-dark mb-0">
                        <input
                            class="form-check-input"
                            :id="`${ckey}-inline-Viernes`"
                            type="checkbox"
                            v-model="formDays"
                            value="5"
                        />
                        <label class="form-check-label" :for="`${ckey}-inline-Viernes`"
                            >Viernes</label
                        >
                    </div>
                    <div class="form-check form-check-inline checkbox checkbox-dark mb-0">
                        <input
                            class="form-check-input"
                            :id="`${ckey}-inline-Sabado`"
                            type="checkbox"
                            v-model="formDays"
                            value="6"
                        />
                        <label class="form-check-label" :for="`${ckey}-inline-Sabado`"
                            >Sabado</label
                        >
                    </div>
                    <div class="form-check form-check-inline checkbox checkbox-dark mb-0">
                        <input
                            class="form-check-input"
                            :id="`${ckey}-inline-Domingo`"
                            type="checkbox"
                            v-model="formDays"
                            value="7"
                        />
                        <label class="form-check-label" :for="`${ckey}-inline-Domingo`"
                            >Domingo</label
                        >
                    </div>
                </div>
                <div class="m-checkbox-inline" v-else>
                    <div class="form-check form-check-inline radio radio-dark mb-0">
                        <input
                            class="form-check-input"
                            :id="`${ckey}-inline-Lunes`"
                            type="radio"
                            v-model="formDay"
                            value="1"
                        />
                        <label class="form-check-label" :for="`${ckey}-inline-Lunes`">Lunes</label>
                    </div>
                    <div class="form-check form-check-inline radio radio-dark mb-0">
                        <input
                            class="form-check-input"
                            :id="`${ckey}-inline-Martes`"
                            type="radio"
                            v-model="formDay"
                            value="2"
                        />
                        <label class="form-check-label" :for="`${ckey}-inline-Martes`"
                            >Martes</label
                        >
                    </div>
                    <div class="form-check form-check-inline radio radio-dark mb-0">
                        <input
                            class="form-check-input"
                            :id="`${ckey}-inline-Miercoles`"
                            type="radio"
                            v-model="formDay"
                            value="3"
                        />
                        <label class="form-check-label" :for="`${ckey}-inline-Miercoles`"
                            >Miercoles</label
                        >
                    </div>
                    <div class="form-check form-check-inline radio radio-dark mb-0">
                        <input
                            class="form-check-input"
                            :id="`${ckey}-inline-Jueves`"
                            type="radio"
                            v-model="formDay"
                            value="4"
                        />
                        <label class="form-check-label" :for="`${ckey}-inline-Jueves`"
                            >Jueves</label
                        >
                    </div>
                    <div class="form-check form-check-inline radio radio-dark mb-0">
                        <input
                            class="form-check-input"
                            :id="`${ckey}-inline-Viernes`"
                            type="radio"
                            v-model="formDay"
                            value="5"
                        />
                        <label class="form-check-label" :for="`${ckey}-inline-Viernes`"
                            >Viernes</label
                        >
                    </div>
                    <div class="form-check form-check-inline radio radio-dark mb-0">
                        <input
                            class="form-check-input"
                            :id="`${ckey}-inline-Sabado`"
                            type="radio"
                            v-model="formDay"
                            value="6"
                        />
                        <label class="form-check-label" f:for="`${ckey}-inline-Sabado`"
                            >Sabado</label
                        >
                    </div>
                    <div class="form-check form-check-inline radio radio-dark mb-0">
                        <input
                            class="form-check-input"
                            :id="`${ckey}-inline-Domingo`"
                            type="radio"
                            v-model="formDay"
                            value="7"
                        />
                        <label class="form-check-label" :for="`${ckey}-inline-Domingo`"
                            >Domingo</label
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-3 row">
            <label class="col-sm-3 col-form-label">H. Inicio</label>
            <div class="col-sm-9">
                <TimeInput v-model="formTimeStart" />
            </div>
        </div>
        <div class="mb-3 row">
            <label class="col-sm-3 col-form-label">H. Final</label>
            <div class="col-sm-9">
                <TimeInput v-model="formTimeEnd" />
            </div>
        </div>
        <div class="mb-3 row" v-if="showPeriodo">
            <label class="col-sm-3 col-form-label">Periodo</label>
            <div class="col-sm-9">
                <input
                    type="number"
                    class="form-control"
                    placeholder="(minutos)"
                    v-model="formPeriodo"
                />
            </div>
        </div>
        <div class="row pt-3">
            <div class="col text-end">
                <button class="btn btn-danger" @click="handleCancel">Cancelar</button>
                <button class="btn btn-primary ms-3" @click="handleSave">Guardar</button>
            </div>
        </div>
    </div>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import { computed, ref, watch, onMounted } from 'vue';
import TimeInput from '@/components/form/TimeInput.vue';
import DateInput from '@/components/form/DateInput.vue';
import { DateTime } from 'luxon';

/** Este formulario no se crea con el patron de los formularios comunes
 * porque es para varias entidades que hay que transformar */
export default {
    name: 'EventForm',
    components: { TimeInput, DateInput },
    props: { type: String },
    setup(props, { emit }) {
        const ckey = ref(Math.random());
        const modeOptionPeriodico = {
            name: 'Periodico',
            value: 'periodico',
        };
        const typeOptionFecha = {
            name: 'Fijo',
            value: 'fijo',
        };
        const id = ref();
        const formType = ref('consulta');
        const formTypes = ref([]);
        const formMode = ref('periodico');
        const formDate = ref();
        const formDays = ref([]);
        const formDay = ref('');
        const formTimeStart = ref();
        const formTimeEnd = ref();
        const formPeriodo = ref();

        const formModeOptions = ref([modeOptionPeriodico]);
        const showDate = ref(false);
        const showDays = ref(true);
        const showPeriodo = ref(true);
        const showBlocked = ref(true);

        const selectType = ref('checkbox');
        const selectClass = ref('form-check-input');
        const selectContainerClases = ref('form-check form-check-inline checkbox checkbox-dark');

        const changedDisabled = computed(() => {
            if (id.value || props.type === 'blocked') {
                return true;
            }
            return false;
        });

        const resetForm = () => {
            id.value = '';
            if (props.type === 'blocked') {
                formType.value = 'blocked';
            } else {
                formType.value = 'consulta';
            }
            formTypes.value = [];
            formMode.value = 'periodico';
            formDate.value = '';
            formDays.value = [];
            formDay.value = '';
            formTimeStart.value = '';
            formTimeEnd.value = '';
            formPeriodo.value = '';
        };

        const checkFormMode = () => {
            if (formMode.value === 'periodico') {
                showDate.value = false;
                showDays.value = true;
            } else {
                showDate.value = true;
                showDays.value = false;
            }
        };

        watch(formType, (nVal) => {
            if (nVal === 'consulta' || nVal === 'procedimientos') {
                formType.value = 'consulta';
                formMode.value = modeOptionPeriodico.value;
                formModeOptions.value = [modeOptionPeriodico];
                showDate.value = false;
                showDays.value = true;
                showPeriodo.value = true;
                selectType.value = 'checkbox';
                selectClass.value = 'form-check-input';
                selectContainerClases.value = 'form-check form-check-inline checkbox checkbox-dark';
                formDay.value = '';
            } else {
                checkFormMode();
                formModeOptions.value = [modeOptionPeriodico, typeOptionFecha];
                showPeriodo.value = false;
                selectType.value = 'radio';
                selectClass.value = 'form-check-input';
                selectContainerClases.value = 'form-check form-check-inline radio radio-dark';
                formDays.value = [];
            }
        });

        watch(formMode, () => {
            checkFormMode();
        });

        const setEvent = (event) => {
            id.value = event.id;
            formType.value = 'consulta';
            formTypes.value = event.types;
            formMode.value = 'periodico';
            formDays.value = event.schedule.map((s) => s.weekDay);
            formTimeStart.value = DateTime.now()
                .set({
                    hour: event.schedule[0].startHour,
                    minute: event.schedule[0].startMin,
                    second: event.schedule[0].startSec,
                })
                .toISO();
            formTimeEnd.value = DateTime.now()
                .set({
                    hour: event.schedule[0].endHour,
                    minute: event.schedule[0].endMin,
                    second: event.schedule[0].endSec,
                })
                .plus({ second: 1 })
                .toISO();
            formPeriodo.value = event.period;
        };

        const setIntervalDisabled = (inDi) => {
            id.value = inDi.id;
            formType.value = 'blocked';
            if (inDi.timeStart) {
                formMode.value = 'fijo';
                formDate.value = inDi.timeStart;
                formTimeStart.value = inDi.timeStart;
                formTimeEnd.value = inDi.timeEnd;
            } else {
                formMode.value = 'periodico';
                formTimeStart.value = DateTime.now()
                    .set({
                        hour: inDi.startHour,
                        minute: inDi.startMin,
                        second: inDi.startSec,
                    })
                    .toISO();
                formTimeEnd.value = DateTime.now()
                    .set({
                        hour: inDi.endHour,
                        minute: inDi.endMin,
                        second: inDi.endSec,
                    })
                    .plus({ second: 1 })
                    .toISO();
                console.log('inDi.weekDay', inDi.weekDay);
                formDay.value = `${inDi.weekDay}`;
            }
        };

        const handleCancel = () => {
            resetForm();
        };

        const handleSave = () => {
            emit('onSave', {
                id: id.value,
                formType: formType.value,
                formTypes: formTypes.value,
                formMode: formMode.value,
                formDate: formDate.value,
                formDays: [...formDays.value],
                formDay: formDay.value,
                formTimeStart: formTimeStart.value,
                formTimeEnd: formTimeEnd.value,
                formPeriodo: formPeriodo.value,
            });
            resetForm();
        };

        onMounted(() => {
            if (props.type === 'blocked') {
                formType.value = 'blocked';
                formMode.value = 'periodico';
            }
            if (props.type === 'citas') {
                showBlocked.value = false;
            }
        });

        return {
            ckey,
            formModeOptions,
            formType,
            formTypes,
            formMode,
            formDate,
            formDays,
            formDay,
            formTimeStart,
            formTimeEnd,
            formPeriodo,
            showDate,
            showDays,
            showPeriodo,
            showBlocked,
            selectType,
            selectClass,
            selectContainerClases,
            changedDisabled,
            handleSave,
            handleCancel,
            setEvent,
            setIntervalDisabled,
        };
    },
};
</script>
<style scoped></style>
