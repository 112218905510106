<template>
    <WindowLayout :title="title">
        <div class="row d-flex justify-content-center">
            <div class="col-lg-12">
                <Calendar
                    :view="calendarViewMode"
                    :especialista="undefined"
                    :intervalsDisabled="intevarls"
                    @onResizeWindows="handleResizeWindows"
                    @onGetIntervalDisabled="handleGetIntervalDisabled"
                    ref="calendar"
                    :key="calendarKey"
                    :hourHeight="24"
                    :hourMin="0"
                    :hourMax="24"
                />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <div class="darmin card">
                    <div class="card-header pb-0 d-flex align-items-center">
                        <h5>{{ formTitle }}</h5>
                    </div>
                    <div class="card-body theme-form">
                        <div class="row">
                            <EventForm ref="eventForm" @onSave="handleSaveEvent" type="blocked" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row d-flex justify-content-center">
            <div class="col-lg-12">
                <div class="darmin card">
                    <div class="card-header pb-0 d-flex align-items-center">
                        <h5>Configuracion</h5>
                    </div>
                    <div class="card-body">
                        <div class="card-block row">
                            <div class="col-sm-12 col-lg-12 col-xl-12">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Tipo</th>
                                                <th>Modo</th>
                                                <th>Configuracion</th>
                                                <th>H. inicio</th>
                                                <th>H. fin</th>
                                                <th>...</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <IntervalDisabledTableRow
                                                v-for="(id, i) in intevarls"
                                                :key="i"
                                                :item="id"
                                                @onEdit="handleEditIntervalDisabled"
                                                @onRemove="handleRemoveIntervalDisabled"
                                                :showAll="true"
                                            />
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </WindowLayout>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import { computed, onBeforeMount, ref, watch } from 'vue';
import WindowLayout from '@/layouts/Window.layout.vue';
import useResize from '../../../services/useResize';
import useIntervalDisabled from './services/useIntervalDisabled';
import Calendar from '../../../components/calendar/calendar.vue';
import EventForm from './components/EventForm.vue';
import IntervalDisabledTableRow from './components/IntervalDisabledTableRow.vue';

export default {
    name: 'AgendaSetup',
    components: {
        Calendar,
        WindowLayout,
        EventForm,
        IntervalDisabledTableRow,
    },
    props: {
        title: { type: String, default: 'Agenda - Configuracion general' },
    },
    setup() {
        const formTitle = ref('Crear/Editar Periodo');
        const calendarViewMode = ref('week');
        const calendarKey = ref(Date.now());

        const { width } = useResize();

        // eslint-disable-next-line operator-linebreak
        const {
            intervalsDisabled,
            getIntervalDisabled,
            buildFilterForSetup,
            buildIntervalDisable,
            createIntervalDisable,
            updateIntervalDisable,
            removeIntervalDisabled,
        } = useIntervalDisabled();

        const intevarls = computed(
            () =>
                // eslint-disable-next-line implicit-arrow-linebreak, comma-dangle
                intervalsDisabled.value?.filter((i) => i.especialista === undefined)
            // eslint-disable-next-line function-paren-newline
        );

        const handleGetIntervalDisabled = () => {
            getIntervalDisabled(buildFilterForSetup(undefined));
        };

        const handleResizeWindows = (widthVal) => {
            if (widthVal < 576) {
                calendarViewMode.value = 'day';
            } else {
                calendarViewMode.value = 'week';
            }
        };

        watch(
            () => width.value,
            (newVal) => {
                handleResizeWindows(newVal);
                // eslint-disable-next-line comma-dangle
            }
        );

        // Formulario
        const eventForm = ref();
        const handleSaveEvent = (dataForm) => {
            console.log('event', dataForm);
            const iD = buildIntervalDisable({
                mode: dataForm.formMode,
                date: dataForm.formDate,
                day: dataForm.formDay,
                timeStart: dataForm.formTimeStart,
                timeEnd: dataForm.formTimeEnd,
                especialistaId: undefined,
            });

            if (dataForm.id) {
                updateIntervalDisable(dataForm.id, iD);
            } else {
                createIntervalDisable(iD);
            }
        };

        // Lista
        const handleRemoveIntervalDisabled = (id) => {
            removeIntervalDisabled(id);
        };

        const handleEditIntervalDisabled = (event) => {
            eventForm.value.setIntervalDisabled(event);
        };

        onBeforeMount(() => {
            handleResizeWindows(width);
            getIntervalDisabled(buildFilterForSetup(undefined));
        });

        return {
            calendarViewMode,
            calendarKey,
            intevarls,
            handleResizeWindows,
            handleGetIntervalDisabled,
            // Formulario
            formTitle,
            eventForm,
            handleSaveEvent,
            // Lista
            handleRemoveIntervalDisabled,
            handleEditIntervalDisabled,
        };
    },
};
</script>
<style></style>
